<template>
    <main style="flex-direction: column;">
            <div class="newformat">
                <div class="col-md-3">
                    <div class="cards-vacantes">
                        <div class="d-flex flex-column" v-for="(item, i) in dataPostulate" :key="i + 1">
                            <div class="J-talento-item" style="cursor:pointer;" v-on:click="viewPostulates(item)">
                                <div class="d-flex" style="justify-content: left; align-items: left;">
                                    <div class="d-flex flex-column">
                                        <div v-if="item.status" style="font-family: Poppins;">
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-if="item.status === 'considerar'" class="text-warning">
                                                A Considerar
                                            </span>
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-else-if="item.status === 'apto'" class="text-success">
                                                Apto
                                            </span>
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-else-if="item.status === 'no apto'" class="text-danger">
                                                No Apto
                                            </span>
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-else-if="item.status === 'contratado'" class="text-primary">
                                                Contratado
                                            </span>
                                            <span
                                                style="display: flex; font-size: 12px; text-transform: capitalize; color: gray;">
                                                Postulado el: {{ moment(item.createdAt).format("DD MMMM") }}
                                            </span>
                                        </div>
                                        <div class="d-flex flex-row" style="justify-content: left; align-items: left;">
                                            <span class="talento-text-title">
                                                {{ item.student.name }}
                                                {{ item.student.lastname }}
                                                {{ item.student.secondlastname }}
                                            </span>
                                        </div>
                                        <div
                                            style="display: flex; flex-direction: row; justify-content: left; font-family: 'Poppins';">
                                            <div v-show="item.student.status === 'exuniversitario'">
                                                <span style="font-size: 12px; text-transform: capitalize; color: gray;"
                                                    v-if="item.student.salarioAproximado">
                                                    ${{ item.student.salarioAproximado }}\
                                                </span>
                                            </div>
                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <box-icon name='current-location' ></box-icon>
                                                &nbsp;
                                                <div v-if="item.student.municipaly">
                                                    <span
                                                        style="font-size: 12px; color: gray; text-transform: uppercase;">
                                                        {{ item.student.municipaly }}, {{ item.student.country }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cont-data-postulantes col-md-9 flex column" v-show="show != true">
                    <!-- Información rápida -->
                    <div style="display: flex; background-color: #ff7a0b; padding: 2%; align-items: center;">
                        <div class="previous-btn mb-2" id="previous" v-show="window <= 1024">
                        <button type="button" class="btn btn-outline-primary"
                            style="display: flex; justify-content: center; align-items: center; font-size: 19px;"
                            @click="returnView()">
                            <box-icon name='arrow-back'></box-icon>
                        </button>
                        </div>
                        <div v-if="informationUser.photo" style="width: 10%; margin: 10px;">
                            <img class="rounded-circle" :src="informationUser.photo.link" alt="Imagen de usuario" loading="eager"
                                style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                        </div>
                        <div v-else style="width: 10%; margin: 10px;"> 
                            <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="eager"
                        style="width: 90%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                        </div>
                        <div style="display: flex; flex-direction: column; width: 90%;">
                            <div style="display: flex;  justify-content: left; align-items: center;">
                                <span
                                    style="font-size: 18px; font-weight: bold; font-family: Poppins; text-transform: uppercase; color: white;">
                                    {{ informationStudent.name }}
                                    {{ informationStudent.lastname }}
                                    {{ informationStudent.secondlastname }}
                                </span>
                            </div>
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="flex: 2;" v-show="informationStudent.status === 'preuniversitario'">
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins; color: white;">
                                        Estudiante de Bachillerato
                                    </span>
                                    <small style="color: white"> / </small>
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins; color: white;">
                                        {{ getEdad(informationStudent.date) }} años
                                    </span>
                                </div>
                                <div style="flex: 1;" v-show="informationStudent.status === 'universitario'">
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins; color: white;">
                                        Estudiante Universitario
                                    </span>
                                    <span style="color: white"> / </span>
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins; color: white;">
                                        {{ getEdad(informationStudent.date) }} años
                                    </span>
                                </div>
                                <div style="flex: 1; display:flex; justify-content: flex-end;">
                                    <div style="display:flex; justify-content: center; align-items: center;">
                                        <div v-if="informationStudent.linkedin">
                                            <a class="J-btn auto" title="LinkedIn" target="_blank" 
                                                v-on:click="openLinkedin(informationStudent.linkedin)">
                                                <box-icon color="white" type='logo' name='linkedin-square'></box-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Nueva navegación -->
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="documents-tab" data-bs-toggle="tab"
                                data-bs-target="#subir-tab-pane" type="button" role="tab" aria-controls="subir-tab-pane"
                                aria-selected="true">Datos de Administración</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                                type="button" role="tab" aria-controls="home-tab-pane" aria-selected="false">Datos
                                Personales</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile-tab-pane" type="button" role="tab"
                                aria-controls="profile-tab-pane" aria-selected="false">Información
                                Escolar</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                data-bs-target="#contact-tab-pane" type="button" role="tab"
                                aria-controls="contact-tab-pane" aria-selected="false">Idiomas</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="disabled-tab" data-bs-toggle="tab"
                                data-bs-target="#disabled-tab-pane" type="button" role="tab"
                                aria-controls="disabled-tab-pane" aria-selected="false">Hobbies</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="experiencia-tab" data-bs-toggle="tab"
                                data-bs-target="#experiencia-tab-pane" type="button" role="tab"
                                aria-controls="experiencia-tab-pane" aria-selected="false">Experiencia</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="documents-tab" data-bs-toggle="tab"
                                data-bs-target="#documents-tab-pane" type="button" role="tab"
                                aria-controls="documents-tab-pane" aria-selected="false">Documentos Becario</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="documents-tab" data-bs-toggle="tab"
                                data-bs-target="#convenios-tab-pane" type="button" role="tab"
                                aria-controls="convenios-tab-pane" aria-selected="false">Convenios</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <!-- Datos de Administración -->
                        <div class="tab-pane fade show active" id="subir-tab-pane" role="tabpanel"
                            aria-labelledby="subir-tab" tabindex="7" style="max-height: 420px; overflow: scroll;">
                            <!-- Muestra los Datos de Administración -->
                            <div style="display: flex; flex-direction: column; padding: 2%;" v-if="this.enableInput">
                                <div style="display: flex; justify-content: space-between; flex-direction: row; margin-bottom: 2%;">
                                    <div>
                                        <span style="font-size: 20px; font-family: Poppins;">
                                            Datos de Administración
                                        </span>
                                    </div>
                                    <div style="display: flex; justify-content: center; align-items: center;">
                                        <box-icon name='edit' v-on:click="editInf()" style="cursor: pointer;"></box-icon>
                                    </div>
                                </div>
                                <div style="display: flex; width: 100%; height: 4rem;">
                                    <span style="flex: 1;"><b>Id de Administración:</b> {{ informationPostulante.idAdministration }}</span>
                                    <span style="flex: 1;"><b>Posición:</b> {{ informationPostulante.position }}</span>
                                    <span style="flex: 1;"><b>Compañia:</b> {{ informationPostulante.company }}</span>
                                </div>
                                <div style="display: flex; width: 100%; height: 4rem;">
                                    <span style="flex: 1;"><b>División:</b> {{ informationPostulante.division }}</span>
                                    <span style="flex: 1;"><b>Departamento:</b> {{ informationPostulante.department }}</span>
                                    <span style="flex: 1;"><b>Mentor:</b> {{ informationPostulante.mentor }}</span>
                                </div>
                                <div style="display: flex; width: 100%; height: 4rem;">
                                    <span style="flex: 1;"><b>Beca Mensual: </b>${{ informationPostulante.salary }}</span>
                                    <span style="flex: 1;"><b>Contacto en la Empresa:</b> {{ informationPostulante.contactCompany }}</span>
                                    <span style="flex: 1;"><b>Número de póliza: </b>{{ informationPostulante.policyNumber }}</span>
                                </div>
                                <div style="display: flex; width: 100%; height: 4rem;">
                                    <span style="flex: 1;"><b>Aseguradora: </b>{{ informationPostulante.insuranceCarrier }}</span>
                                    <span style="flex: 1;"><b>Banco:</b> {{ informationPostulante.bank }}</span>
                                    <span style="flex: 1;"><b>Número de cuenta: </b>{{ informationPostulante.bankAccount }}</span>
                                </div>
                                <div style="display: flex; width: 100%; height: 4rem;">
                                    <span style="flex: 1;"><b>Fecha de ingreso:</b>
                                        {{ moment(informationPostulante.dateHired).format("DD/MM/YYYY") }}
                                    </span>
                                    <span style="flex: 1;"><b>Termino 1er Convenio:</b> 
                                        {{  moment(informationPostulante.dateFirstPeriod).format("DD/MM/YYYY") }}
                                    </span>
                                    <span style="flex: 1;"><b>Termino 2do Convenio:</b> 
                                        {{ moment(informationPostulante.dateSecondPeriod).format("DD/MM/YYYY") }}
                                    </span>
                                </div>
                                <hr>
                                <div style="display: flex; width: 100%; height: 4rem;">
                                    <span style="flex: 1;"><b>Evaluación Final: </b>{{ informationPostulante.evaluation }}</span>
                                </div>
                                <hr>
                                <div style="display: flex; width: 100%;">
                                    <span class="flex"><b>Comentarios: </b>
                                        <span v-html="informationPostulante.comments"></span>
                                    </span>
                                </div>
                                <hr>
                            </div>
                            <!-- Formulario Datos de Administracion -->
                            <div class="shadow p-3 mb-5 bg-body-tertiary rounded" v-else-if="!this.enableInput">
                                <h5 style="padding: 2rem;">Datos de Administración</h5>
                                <form @submit.prevent="DataAdministration()"
                                    style="display: flex; flex-wrap: wrap; gap: 1rem; justify-content: center;">
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="idAdministration">
                                            <label for="floatingInput">Id de Administración</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="position">
                                            <label for="floatingInput">Posición</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="company">
                                            <label for="floatingInput">Compañia/Empresa</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="division">
                                            <label for="floatingInput">División</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="department">
                                            <label for="floatingInput">Departamento</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="mentor">
                                            <label for="floatingInput">Mentor</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="salary">
                                            <label for="floatingInput">Beca Mensual</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="policyNumber">
                                            <label for="floatingInput">Número de póliza</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="insuranceCarrier">
                                            <label for="floatingInput">Aseguradora</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="bank">
                                            <label for="floatingInput">Banco</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="bankAccount">
                                            <label for="floatingInput">Cuenta Bancaria</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="contactCompany">
                                            <label for="floatingInput">Contacto en la Empresa</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="date" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="dateHired">
                                            <label for="floatingInput">Fecha de Ingreso</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="date" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="dateFirstPeriod">
                                            <label for="floatingInput">Fecha Termino 1er Convenio</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <input type="date" class="form-control" id="floatingInput" min="5" max="255"
                                                placeholder="Nombre" autocomplete="off" v-model="dateSecondPeriod">
                                            <label for="floatingInput">Fecha Termino 2do Convenio</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                                                v-model="evaluation">
                                                <option value="" selected disabled>
                                                Selecciona una opción
                                                </option>
                                                <option value="Sobresaliente">Sobresaliente</option>
                                                <option value="Muy bien">Muy bien</option>
                                                <option value="Bien">Bien</option>
                                                <option value="Necesita Mejorar">Necesita Mejorar</option>
                                            </select>
                                            <label for="floatingSelect">Evaluación Final</label>
                                        </div>
                                    </div>
                                    <div class="col-md-9 mb-5">
                                        <QuillEditor id="editor" theme="snow" placeholder="Comentarios Finales"
                                            v-model:content="comments" contentType="html"
                                            :toolbar="['bold', 'italic', 'underline', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }]" />
                                    </div>
                                    <div class="d-grid gap-2 col-6 mx-auto">
                                        <button class="btn btn-primary" :disabled="response.loading" type="submit">
                                            Subir información
                                            <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                        <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">
                                            Cancelar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Información Personal -->
                        <div class="tab-pane fade" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                            <div style="padding: 2rem;">
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style="display: flex; width: 100%; height: 4rem;">
                                        <span style="flex: 1;"><b>Nombre:</b> 
                                            {{ informationStudent.name }}
                                            {{ informationStudent.lastname }}
                                            {{ informationStudent.secondlastname }}
                                        </span>
                                        <span style="flex: 1;" v-if="informationStudent.date"><b>Edad:</b> {{ getEdad(informationStudent.date) }} años</span>
                                        <span style="flex: 1;"><b>Fecha de Nacimiento:</b> 
                                            {{ moment(informationStudent.date).format('DD/MM/YYYY') }} 
                                        </span>
                                    </div>
                                    <div style="display: flex; width: 100%; height: 4rem;">
                                        <span style="flex: 1;"><b>Género:</b> {{ informationStudent.gender }}</span>
                                        <span style="flex: 1;"><b>Celular:</b> {{ informationStudent.phone }}</span>
                                        <span style="flex: 1;"><b>Email:</b> {{ informationStudent.email }}</span>
                                    </div>
                                    <div style="display: flex; width: 100%; height: 4rem;">
                                        <span style="flex: 1;"><b>Estado:</b> {{ informationStudent.estadoRepublica }}</span>
                                        <span style="flex: 1;"><b>Municipio/Delegación:</b> {{ informationStudent.municipaly }}</span>
                                        <span style="flex: 1;"><b>Colonia:</b> {{ informationStudent.delegation }}</span>
                                    </div>
                                    <div style="display: flex; width: 100%; height: 4rem;">
                                        <span style="flex: 1;"><b>Pais:</b> {{ informationStudent.country }}</span>
                                        <span style="flex: 1;"><b>CURP:</b> {{ informationStudent.curp }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Información Escolar -->
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                            tabindex="0">
                            <div class="flex mt-3" style="padding: 2rem;">
                                <div style="display: flex; flex-direction: column;">
                                    <div style="display: flex; justify-content: left; align-items: center;"
                                        v-show="informationStudent.status != 'exuniversitario'">
                                        <div class="row">
                                            <div class="text-justify m-2"><b>Escuela:</b> {{ informationStudent.school
                                                }}
                                            </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.status != 'exuniversitario'">
                                            <div class="text-justify m-2"><b>Plantel:</b> {{ informationStudent.plantel
                                                }}
                                            </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.status != 'exuniversitario'">
                                            <div class="text-justify m-2"><b>Carrera:</b> {{ informationStudent.career
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: row;">
                                    <div v-if="informationStudent.status == 'universitario'"
                                        style="display: flex; flex-direction: row;">
                                        <div class="row">
                                            <!-- Años cursados -->
                                            <div class="text-justify m-2"
                                                v-if="informationStudent.yearActual == 'MedioAño'">
                                                <b>Años
                                                    Cursados: </b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'UnAño'">
                                                <b>Años
                                                    Cursados: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'AñoyMedio'">
                                                <b>Años
                                                    Cursados: </b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'DosAños'">
                                                <b>Años
                                                    Cursados: </b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'DosAñosyMedio'">
                                                <b>Años Cursados: </b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Tres Años'">
                                                <b>Años
                                                    Cursados: </b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Tres Años y Medio'">
                                                <b>Años Cursados: </b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cuatro'">
                                                <b>Años
                                                    Cursados: </b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cuatro Años y Medio'"><b>Años
                                                    Cursados:
                                                </b> 4
                                                Años y medio </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cinco'">
                                                <b>Años
                                                    Cursados: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'CincoAñosyMedio'">
                                                <b>Años Cursados: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'SeisAños'">
                                                <b>Años
                                                    Cursados: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'SeisAñosyMedio'">
                                                <b>Años Cursados: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'SieteAños'">
                                                <b>Años
                                                    Cursados: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'SieteAñosyMedio'">
                                                <b>Años Cursados: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == '+OchoAños'">
                                                <b>Años Cursados: </b> Más de 8 años
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Duración de la carrera -->
                                            <div class="text-justify m-2" v-if="informationStudent.yearTotal == 'MedioAño'">
                                                <b>Duración de la carrera: </b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'UnAño'">
                                                <b>Duración de la carrera: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'AñoyMedio'">
                                                <b>Duración de la carrera: </b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'DosAños'">
                                                <b>Duración
                                                    de la carrera: </b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'DosAñosyMedio'">
                                                <b>Duración de la carrera: </b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Tres Años'">
                                                <b>Duración de la carrera: </b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Tres Años y Medio'">
                                                <b>Duración de la carrera: </b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Cuatro'">
                                                <b>Duración
                                                    de la carrera: </b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Cuatro Años y Medio'">
                                                <b>Duración de la carrera: </b> 4 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Cinco'">
                                                <b>Duración de
                                                    la carrera: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'CincoAñosyMedio'">
                                                <b>Duración de la carrera: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SeisAños'">
                                                <b>Duración
                                                    de la carrera: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SeisAñosyMedio'">
                                                <b>Duración de la carrera: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SieteAños'">
                                                <b>Duración de la carrera: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SieteAñosyMedio'">
                                                <b>Duración de la carrera: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == '+OchoAños'">
                                                <b>Duración de la carrera: </b> Más de 8 años
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="informationStudent.status == 'preuniversitario'"
                                        style="display: flex; flex-direction: row;">
                                        <div class="row">
                                            <!-- Años cursados -->
                                            <div class="text-justify m-2"
                                                v-if="informationStudent.yearActual == 'Medio Año'">
                                                <b>Años Cursados:</b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Un Año'">
                                                <b>Años Cursados: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Año y Medio'">
                                                <b>Años Cursados:</b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Dos Años'">
                                                <b>Años
                                                    Cursados:</b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Dos Años y Medio'">
                                                <b>Años Cursados:</b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Tres Años'">
                                                <b>Años
                                                    Cursados:</b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Tres Años y Medio'">
                                                <b>Años Cursados:</b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cuatro'">
                                                <b>Años
                                                    Cursados:</b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cuatro Años y Medio'"><b>Años
                                                    Cursados:
                                                </b> 4
                                                Años y medio </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cinco'">
                                                <b>Años
                                                    Cursados: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Cinco Años y Medio'">
                                                <b>Años Cursados: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Seis Años'">
                                                <b>Años
                                                    Cursados: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Seis Años y Medio'">
                                                <b>Años Cursados: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Siete Años'">
                                                <b>Años
                                                    Cursados: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == 'Siete Años y Medio'">
                                                <b>Años Cursados: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearActual == '+Ocho Años'">
                                                <b>Años
                                                    Cursados: </b> Más de 8 años
                                            </div>

                                        </div>
                                        <div class="row">
                                            <!-- Duración de la carrera -->
                                            <div class="text-justify m-2" v-if="informationStudent.yearTotal == 'MedioAño'">
                                                <b>Duración
                                                    de
                                                    bachillerato: </b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'UnAño'">
                                                <b>Duración de
                                                    bachillerato: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'AñoyMedio'">
                                                <b>Duración de bachillerato: </b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'DosAños'">
                                                <b>Duración
                                                    de bachillerato: </b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'DosAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Tres Años'">
                                                <b>Duración de bachillerato: </b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Tres Años y Medio'">
                                                <b>Duración de bachillerato: </b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Cuatro'">
                                                <b>Duración
                                                    de bachillerato: </b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Cuatro Años y Medio'">
                                                <b>Duración de bachillerato: </b> 4 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'Cinco'">
                                                <b>Duración de
                                                    bachillerato: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'CincoAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SeisAños'">
                                                <b>Duración
                                                    de bachillerato: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SeisAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SieteAños'">
                                                <b>Duración de bachillerato: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == 'SieteAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="informationStudent.yearTotal == '+OchoAños'">
                                                <b>Duración de bachillerato: </b> Más de 8 años
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="informationStudent.status != 'exuniversitario'">
                                                <div class="text-justify m-2"><b>Promedio:</b> {{ informationStudent.promedio
                                                    }}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Idiomas -->
                        <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                            tabindex="0">
                            <div class="flex mt-3" style="padding: 2rem;">
                                <div class="row" v-for="(exp, i) in informationStudent.idiomas" :key="i + 1">
                                    <div class="col-4 text-justify m-2"> <b>Idioma {{ i + 1 }}</b> {{ exp.idioma }}
                                    </div>
                                    <div class="col-4 text-justify m-2"> <b>Nivel:</b> {{ exp.nivel }} </div>
                                </div>
                            </div>
                        </div>
                        <!-- Hobbies -->
                        <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"
                            tabindex="0">
                            <div class="flex mt-3" style="padding: 2rem;">
                                <div class="row mb-2" v-for="(exp, i) in informationStudent.hobbies" :key="i + 1">
                                    <div class="col text-justify"> <b>Hobbie: </b> {{ exp.name }}</div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col text-justify" v-if="informationStudent.hobbieWrite1"> <b>Hobbie:</b>
                                        {{ informationStudent.hobbieWrite1 }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col text-justify" v-if="informationStudent.hobbieWrite2"> <b>Hobbie:</b>
                                        {{ informationStudent.hobbieWrite2 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Experiencia -->
                        <div class="tab-pane fade" id="experiencia-tab-pane" role="tabpanel"
                            aria-labelledby="experiencia-tab" tabindex="0">
                            <div class="flex" style="padding: 2rem;">
                                <div v-for="(exp, i) in informationStudent.experiencia" :key="i + 1">
                                    <div v-if="exp.experienciaCompany"
                                        style="display: flex; flex-direction: row; justify-content: left; align-items: left; margin-top: 10px;">
                                        <div class="row">
                                            <div class="text-justify m-2"> <b>Nombre de la Empresa:</b>
                                                {{ exp.experienciaCompany }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2"> <b>Puesto:</b>
                                                {{ exp.experienciaPuesto }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="exp.experienciaCompany"
                                        style="display: flex; flex-direction: row; justify-content: left; align-items: left;">
                                        <div class="row">
                                            <div class="text-justify m-2"> <b>Salario: </b>
                                                ${{ exp.experienciaSalario }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2"><b>Fecha de Inicio:</b>
                                                {{ exp.experienciaFechaInicio }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div v-if="exp.experienciaFechaFin" class="text-justify m-2"><b>Fecha de
                                                    Termino:</b>
                                                {{ exp.experienciaFechaFin }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row mt-3 mb-2" style="margin-left: 10px;">
                                        <span class="text-center">Sin Experiencia Previa</span>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                        <!-- Documentos -->
                        <div class="tab-pane fade" id="documents-tab-pane" role="tabpanel"
                            aria-labelledby="documents-tab" tabindex="0" style="max-height: 370px; overflow: scroll;">
                            <div style="display:flex; flex-direction: column; align-items: flex-start; padding: 2rem;">
                                <!-- 1 CV -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationStudent?.filecv">
                                    <a class="J-btn auto" title="CV" target="_blank"
                                        :href="informationStudent.filecv?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>CV</small>
                                    </a>
                                </div>
                                <!-- 2 Comp de Estudios -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationStudent?.filecomprobantes">
                                    <a class="J-btn auto" title="Comprobante de Estudios" target="_blank"
                                        :href="informationStudent?.filecomprobantes?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Comprobante de Estudios</small>
                                    </a>
                                </div>
                                <!-- 3 Comp Domicilio -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_comprobante">
                                    <a class="J-btn auto" title="Comprobante de Domicilio" target="_blank"
                                        :href="informationPostulante?.document_comprobante?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Comprobante de Domicilio</small>
                                    </a>
                                </div>
                                <!-- 4 INE -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_ine">
                                    <a class="J-btn auto" title="INE" target="_blank"
                                        :href="informationPostulante?.document_ine?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>INE</small>
                                    </a>
                                </div>
                                <!-- 5 Acta -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_acta">
                                    <a class="J-btn auto" title="Acta de Nacimiento" target="_blank"
                                        :href="informationPostulante?.document_acta?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Acta de Nacimiento</small>
                                    </a>
                                </div>
                                <!-- 6 CURP -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_curp">
                                    <a class="J-btn auto" title="CURP" target="_blank"
                                        :href="informationPostulante?.document_curp?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>CURP</small>
                                    </a>
                                </div>
                                <!-- 7 RFC -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_rfc">
                                    <a class="J-btn auto" title="Constancia de Situación Fiscal" target="_blank"
                                        :href="informationPostulante?.document_rfc?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Constancia de Situación Fiscal</small>
                                    </a>
                                </div>
                                <!-- 8 imss/nss -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_seguro">
                                    <a class="J-btn auto" title="Solicitud de poliza contra accidentes" target="_blank"
                                        :href="informationPostulante?.document_seguro?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Solicitud de poliza contra accidentes</small>
                                    </a>
                                </div>
                                <!-- 9 Hoja de datos personales -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_personales">
                                    <a class="J-btn auto" title="Hoja de datos personales" target="_blank"
                                        :href="informationPostulante?.document_personales?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Hoja de datos personales</small>
                                    </a>
                                </div>
                                <!-- 10 seguro de accidentes -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_accidentes">
                                    <a class="J-btn auto" title="Seguro de accidentes" target="_blank"
                                        :href="informationPostulante?.document_accidentes?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Seguro facultativo/SGMM</small>
                                    </a>
                                </div>
                                <!-- 11 historial académico  -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_historialAcademico">
                                    <a class="J-btn auto" title="Historial Académico" target="_blank"
                                        :href="informationPostulante?.document_historialAcademico?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Historial Académico</small>
                                    </a>
                                </div>
                                <!-- 12 horario escolar  -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_horario">
                                    <a class="J-btn auto" title="Horario Escolar" target="_blank"
                                        :href="informationPostulante?.document_horario?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Horario Escolar</small>
                                    </a>
                                </div>
                                <!-- 13 Comp de Inscripción  -->
                                <div style="display:flex; justify-content: center; align-items: center;"
                                    v-if="informationPostulante?.document_ComprobanteInscripcion">
                                    <a class="J-btn auto" title="Comprobante de inscripción" target="_blank"
                                        :href="informationPostulante?.document_ComprobanteInscripcion?.link">
                                        <box-icon name='check' color="##39811d"></box-icon>
                                        <small>Comprobante de Inscripción</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Convenios -->
                        <div class="tab-pane fade" id="convenios-tab-pane" role="tabpanel" aria-labelledby="convenios-tab" tabindex="0"
                            style="padding: 1rem; max-height: 570px; overflow: scroll;">
                            <div style="height: 100vh;" v-if="this.enableInput2">
                                <div style="display: flex; justify-content: space-between; flex-direction: row;">
                                        <button class="btn btn-primary" style="display: flex; justify-content: center; align-items: center;"
                                        v-on:click="editInf2()" v-if="!informationPostulante?.document_convenio1 && !informationPostulante?.document_convenio2 && !informationPostulante?.document_convenio3">
                                            <span style="margin-right: 3px; font-size: 14px;">Subir Contrato</span>
                                            <box-icon color="white" width="8" name='edit'></box-icon>
                                        </button> 
                                        <button class="btn btn-primary" style="display: flex; justify-content: center; align-items: center;"
                                        v-on:click="editInf2()" v-else>
                                            <span style="margin-right: 3px; font-size: 14px;">Editar Contrato</span>
                                            <box-icon color="white" width="8" name='edit'></box-icon>
                                        </button>   
                                </div>
                                <div v-show="informationPostulante?.document_convenio1 && informationPostulante?.document_convenio2 && informationPostulante?.document_convenio3"
                                    style="display:flex; justify-content: center; align-items: flex-start; padding: 2rem;">
                                    <div style="display:flex; justify-content: center; align-items: center;"
                                        v-if="informationPostulante?.document_convenio1">
                                        <a class="J-btn auto" title="Convenio " target="_blank"
                                            :href="informationPostulante?.document_convenio1?.link">
                                            <box-icon name='check' color="#39811d"></box-icon>
                                            <small>Convenio 1</small>
                                        </a>
                                    </div>
                                    <div style="display:flex; justify-content: center; align-items: center;"
                                        v-if="informationPostulante?.document_convenio2">
                                        <a class="J-btn auto" title="Convenio 2" target="_blank"
                                            :href="informationPostulante?.document_convenio2?.link">
                                            <box-icon name='check' color="#39811d"></box-icon>
                                            <small>Convenio 2</small>
                                        </a>
                                    </div>
                                    <div style="display:flex; justify-content: center; align-items: center;"
                                        v-if="informationPostulante?.document_convenio3">
                                        <a class="J-btn auto" title="Convenio3" target="_blank"
                                            :href="informationPostulante?.document_convenio3?.link">
                                            <box-icon name='check' color="#39811d"></box-icon>
                                            <small>Convenio 3</small>
                                        </a>
                                    </div>
                                </div>
                                <div v-show="!informationPostulante?.document_convenio1 && !informationPostulante?.document_convenio2 && !informationPostulante?.document_convenio3" 
                                    style="display: flex; justify-content:center; align-items:center;">
                                        <h4>Aún no tienes convenios para ver</h4>
                                        <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="eager"
                                            style="width: 20%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                                </div>
                            </div>
                            <div v-else-if="!this.enableInput2">
                                <h5 style="text-align: center;">SUBIR O ACTUALIZAR CONVENIOS</h5>
                                    <div style="display: flex; flex-wrap: wrap ;justify-content: center; margin-bottom: 2rem;">
                                        <div style="flex: 1">
                                            <UploadPartials title="Convenio 1" @data="documentConvenio1" />
                                        </div>
                                        <div style="flex: 1">
                                            <UploadPartials title="Convenio 2" @data="documentConvenio2" required />
                                        </div>
                                        <div style="flex: 1">
                                            <UploadPartials title="Convenio 3" @data="documentConvenio3" required />
                                        </div>
                                    </div>
                                    <div class="d-grid gap-2 col-6 mx-auto">
                                        <button class="btn btn-primary" :disabled="response.loading" type="button"
                                            @click="updateDocuments()">
                                            Subir Documentos
                                            <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                        <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf2()">
                                            Cancelar
                                        </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </main>
</template>

<script lang="js">
import * as ClientService from "../../services/client"
import UploadPartials from "@/components/partials/upload.partials.vue";
import moment from "moment";
moment.locale("es");

export default {
    data: () => ({
        moment: moment,
        file_name: null,
        file: null,
        dataPostulate: [],
        dataPostulantetemp: [],
        informationPostulante: {},
        informationStudent: {},
        informationUser: {},
        searchInput2: "",
        id: "",
        show: "",
        idPostulante: "",
        statusPostulante: "",
        document_convenio1: "",
        document_convenio2: "",
        document_convenio3: "",
        idAdministration: "",
        position: "",
        mentor: "",
        company: "",
        contactCompany: "",
        division: "",
        department: "",
        salary: "",
        policyNumber: "",
        insuranceCarrier: "",
        bank: "",
        bankAccount: "",
        dateHired: "",
        dateFirstPeriod: "",
        dateSecondPeriod: "",
        evaluation: "",
        comments: "",
        response: {
            loading: false
        },
        id_vacante: "",
        enableInput: true,
        enableInput2: true
    }),
    mounted() {
        this.id = this.$route.params.id;
        this.id_vacante = this.$route.params.id;
        this.getPostulates(this.id);
        this.show = true;
    },
    components: {
        UploadPartials
    },

    methods: {
        async getPostulates(id) {
            try {
                let resp = await ClientService.CandidateContratadoClients({ id: id });
                let { data } = resp.data;
                this.dataPostulate = data;
                this.dataPostulantetemp = data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async viewInfo(item) {
            item.view = !item.view;
        },
        //TODO:Ordenar Postulantes Por Edad
        sortEdad(postulante) {
            postulante.sort((a, b) => {
                if (a.student.date == b.student.date) {
                    return 0;
                }
                if (a.student.date > b.student.date) {
                    return -1;
                }
                return 1;
            });
            return postulante;
        },
        //TODO: Calcular Edad De Postulantes
        getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            return edad
        },
        viewPostulates(item) {
            this.informationPostulante = item;
            this.show = Object.entries(this.informationPostulante).length === 0;
            let { student, user, _id, status } = this.informationPostulante;
            this.idPostulante = _id;
            this.informationStudent = student;
            this.informationUser = user;
            this.statusPostulante = status;
        },
        openLinkedin(url) {
            let word = "https://"
            url.includes(word) ? window.open(url) : window.open("https://" + url);
        },

        async documentConvenio1(data) {
            this.document_convenio1 = data;
        },
        async documentConvenio2(data) {
            this.document_convenio2 = data;
        },
        async documentConvenio3(data) {
            this.document_convenio3 = data;
        },
        async DataAdministration() {
            let dataAdministration = {
                _id_vacante: this.id_vacante,
                _id_user: this.informationUser._id,
                idAdministration: this.idAdministration,
                position: this.position,
                mentor: this.mentor,
                company: this.company,
                contactCompany: this.contactCompany,
                division: this.division,
                department: this.department,
                salary: this.salary,
                policyNumber: this.policyNumber,
                insuranceCarrier: this.insuranceCarrier,
                bank: this.bank,
                bankAccount: this.bankAccount,
                dateHired: this.dateHired,
                dateFirstPeriod: this.dateFirstPeriod,
                dateSecondPeriod: this.dateSecondPeriod,
                evaluation: this.evaluation,
                comments: this.comments
            }
            await ClientService.UpdateDataAdminstration(dataAdministration).then((response) => {
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.response.loading = false
                window.location.reload();
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.response.loading = false

            });

        },
        updateDocuments() {
            if (this.document_convenio1 === "") {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    text: "¡Subir convenio 1!",
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
            else if (this.document_convenio2 === "") {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    text: "¡Subir Convenio 2!",
                    showConfirmButton: false,
                    timer: 3500,
                });

            }
            else if (this.document_convenio3 === "") {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    text: "¡Subir convenio 3!",
                    showConfirmButton: false,
                    timer: 3500,
                });

            } else {
                this.response.loading = true
                let updateDocumentos = {
                    _id_vacante: this.id_vacante,
                    document_convenio1: this.document_convenio1,
                    document_convenio2: this.document_convenio2,
                    document_convenio3: this.document_convenio3,
                }
                ClientService.UploadConveniosCompany(updateDocumentos).then((response) => {
                    this.$swal({
                        position: "top-center",
                        icon: "success",
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });

                    this.response.loading = false
                }).catch((e) => {
                    this.$swal({
                        position: "top-center",
                        icon: "error",
                        title: e.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.response.loading = false

                });
            }

        },
        editInf() {
            this.enableInput == true ? this.enableInput = false : this.enableInput = true;
        },
        editInf2() {
            this.enableInput2 == true ? this.enableInput2 = false : this.enableInput2 = true;
        },
    },
};
</script>

<style land="scss" scoped>
@import "../../styles/informacionpostulantes.scss";
</style>